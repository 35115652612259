<template>
  <div class="body" v-if="detail">
    <div class="header flex justify-center">
      <el-button style="margin-right:20px" type="primary" @click="openPopup">打印报告</el-button>
      <router-link to="/">
        <el-button type="warning">回到首页</el-button>
      </router-link>
    </div>

    <vue-easy-print tableShow ref="easyPrint">
      <div ref="report" class="report_body">
        <div class="report">
          <div class="wrap">
            <div class="title">{{detail.reportName}}</div>
            <div class="patient_info flex justify-between align-center">
              <div>姓名：{{detail.patientName}} 性别：{{detail.gender==='Male'?'男':'女'}} 年龄：{{detail.age}}</div>
              <div>{{date}}</div>
            </div>
            <table border="1" class="table">
              <tbody>
                <tr>
                  <td rowspan="2" width="12%">影像</td>
                  <td width="44%">OD</td>
                  <td width="44%">OS</td>
                </tr>
                <tr class="octImg">
                  <td valign="top"><img preview="0" :src="baseUrl+detail.coverImageR" alt=""></td>
                  <td valign="top"><img preview="0" :src="baseUrl+detail.coverImageL" alt=""></td>
                </tr>
                <tr>
                  <td>AI结论</td>
                  <td>{{detail.aiOpinionR}}</td>
                  <td>{{detail.aiOpinionL}}</td>
                </tr>
                <tr>
                  <td>诊断意见</td>
                  <td class="doctorOpinion" colspan="2" align="left">{{detail.doctorOpinion}}</td>
                </tr>
                <tr>
                  <td colspan="3" align="right" style="padding-right:20px;">医生签字:__________</td>
                </tr>
              </tbody>
            </table>
            <div style="color: #1d73bc;">注：AMD：年龄相关性黄斑变性</div>
          </div>
          <div class="footer">来源:比格威医疗科技AI</div>
        </div>
        <div style="page-break-after:always"></div>
        <div v-if="detail.resultImagesR.length>0||detail.resultImagesL.length>0" class="report2">
          <div class="wrap2">
            <div class="title">{{detail.reportName}}</div>
            <div class="patient_info flex justify-between align-center">
              <div>姓名：{{detail.patientName}} 性别：{{detail.gender==='Male'?'男':'女'}} 年龄：{{detail.age}}</div>
              <div>{{date}}</div>
            </div>
            <table border="1" class="table">
              <tbody>
                <tr>
                  <!-- <td rowspan="2" width="10%">影像</td> -->
                  <td width="50%">OD</td>
                  <td width="50%">OS</td>
                </tr>
                <tr class="octImg">
                  <td><img v-for="(item,index) in detail.resultImagesR" :key="index" :preview="'R'+index" :src="baseUrl+item" alt=""></td>
                  <td><img v-for="(item,index) in detail.resultImagesL" :key="index" :preview="'L'+index" :src="baseUrl+item" alt=""></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </vue-easy-print>
  </div>
</template>

<script>
import vueEasyPrint from "vue-easy-print";
import Service from '@/api/service'
export default {
  name: "Report",
  components: {
    vueEasyPrint
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      tableData: [],
      id: '',
      detail: null,
      date: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail()
    let time = new Date().getTime()
    this.date = this.timestampToTime(time)
  },

  methods: {
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear();
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      let hh = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours());
      let mm = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
      return Y + '-' + M + '-' + D + ' ' + hh + ':' + mm
    },
    async getDetail() {
      let data = {
        examId: this.id
      }
      this.detail = await Service.getExamDetails(data)
    },
    openPopup() {
      this.$refs.easyPrint.print();
    }
  }
};
</script>
<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
}
.table {
  border: 1px solid #ccc;
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  text-align: center;
  color: #1d73bc;
  table-layout: fixed;
}
.report_body {
  width: 210mm;
  margin: 0 auto;
}
.octImg img {
  width: 100%;
  cursor: pointer;
}
td {
  padding: 10px;
  word-wrap: break-word;
}

.report {
  width: 210mm;
  height: 297mm;
  margin: 0px auto;
  box-sizing: border-box;
  position: relative;
}
.report2 {
  width: 210mm;
  margin: 0px auto;
  box-sizing: border-box;
}
.wrap {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}
.footer {
  position: absolute;
  bottom: 5px;
  font-size: 7px;
  width: 100%;
  text-align: center;
}
.wrap2 {
  width: 100%;
  //   height: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 20px;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.patient_info {
  margin-top: 15px;
  height: 60px;
}
.header {
  margin: 10px auto;
}
</style>